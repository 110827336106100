import React, { useEffect, useContext, useState } from "react";
import {
  Form,
  Input,
  Button,
  Divider,
  DatePicker,
  Select,
  Row,
  Col,
  message,
  InputNumber,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Context } from "../context/store";
import moment from "moment";
import { baseAPIUrl } from "../constants/urlConstants";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import ACTIONS from "../context/actions";

const { Option } = Select;

export const HomogenizationCreator = (props) => {
  //array of ids for lots selected already
  const [isDisabled, setIsDisabled] = useState(
    new Array(props.lots.length).fill(false)
  );

  const [state, dispatch] = useContext(Context);

  const handleDeselect = (removedID) => {
    //actually wanna use == here since one id is a num and the other string
    // eslint-disable-next-line eqeqeq
    const indexOfLot = props.lots.findIndex((lot) => lot.id == removedID);
    isDisabled[indexOfLot] = false;
    let tempArray = JSON.parse(JSON.stringify(isDisabled));
    setIsDisabled(tempArray);
  };

  const handleSelect = (addedID) => {
    // eslint-disable-next-line eqeqeq
    const indexOfLot = props.lots.findIndex((lot) => lot.id == addedID);
    isDisabled[indexOfLot] = true;
    //F in the chat for JS making you do the DEEP copy
    let tempArray = JSON.parse(JSON.stringify(isDisabled));
    setIsDisabled(tempArray);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Error in saving data");
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    //check if all lots are used. If not then can't submit. If need to paritally submit will work on that in the future
    if (isDisabled.includes(false)) {
      message.error("Not all lots are used can not submit");
      return false;
    } else {
      console.log("Success:", values);
    }

    fetch(baseAPIUrl + "sourcemeds/homogenization/" + props.id, {
      method: "POST",
      body: JSON.stringify({
        ...values,
        batchID: props.batchID,
      }),
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    })
      .then((res) => {
        //check if need to login again
        console.log(`result: ${res}`);
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res;
      })
      .then(
        (results) => {
          console.log("Saved hompginization data");
          message.success("Saved data");
          props.onSubmitGroups();
          //TODO why not working and then close the ticket as normal
        },
        (error) => {
          console.log(`error after res`);
          console.log(error);
        }
      );
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Divider>Homogenization info:</Divider>
      <Form.List name="lots" initialValue={[1]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <>
                <Row>
                  <Col span={16}>
                    <Form.Item
                      {...restField}
                      name={[name, "med_name"]}
                      fieldKey={[fieldKey, "med_name"]}
                      label="name/date"
                      rules={[
                        {
                          required: true,
                          message: "Missing name",
                        },
                      ]}
                    >
                      <Input placeholder="Name for group" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      //label="Group Details"
                      {...restField}
                      name={[name, "homogenization_date_time"]}
                      fieldKey={[fieldKey, "homogenization_date_time"]}
                      rules={[{ required: true, message: "Missing Date" }]}
                    >
                      <DatePicker
                        showTime={{ format: "HH:mm" }}
                        format="MM/DD/YYYY"
                        //disable selecting a day before today
                        disabledDate={(current) =>
                          current &&
                          current < moment().endOf("day").subtract(1, "day")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "homogenization_temp"]}
                      fieldKey={[fieldKey, "homogenization_temp"]}
                      //label="Temp"
                      rules={[
                        {
                          required: true,
                          message: "Missing Temp",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Temperature(F)" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "homogenization_speed"]}
                      fieldKey={[fieldKey, "homogenization_speed"]}
                      //label="Speed"
                      rules={[
                        {
                          required: true,
                          message: "Missing Speed",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Speed(RPM)" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "homogenization_completed_by"]}
                      fieldKey={[fieldKey, "homogenization_completed_by"]}
                      //label="Completed by"
                      rules={[
                        {
                          required: true,
                          message: "Missing name of completor",
                        },
                      ]}
                    >
                      <Input placeholder="Completed by..." />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, "final_gross"]}
                      fieldKey={[fieldKey, "final_gross"]}
                      label="Final Gross Wt"
                      rules={[
                        {
                          required: true,
                          message: "Missing final gross weight",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Final Gross Weight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, "final_net"]}
                      fieldKey={[fieldKey, "final_net"]}
                      label="Final Net Wt"
                      rules={[
                        {
                          required: true,
                          message: "Missing final net weight",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Final Net Weight" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={23}>
                    <Form.Item
                      {...restField}
                      name={[name, "groups"]}
                      fieldKey={[fieldKey, "groups"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing lots",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear={false}
                        style={{
                          width: "90%",
                        }}
                        placeholder="Please select lots to homogenize"
                        //onChange={handleChange}
                        optionLabelProp="label"
                        onSelect={handleSelect}
                        onDeselect={handleDeselect}
                      >
                        {props.lots.map((lot, i) => (
                          <Option
                            key={lot.id}
                            label={lot.package_id}
                            //check if something is disabled as it is already selected elsewhere
                            hidden={isDisabled[i]}
                          >{`${lot.package_id}(Net Wt: ${lot.net_weight})(GWT: ${lot.gross_weight})`}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </Col>
                </Row>

                <Divider />
              </>
            ))}
            <Form.Item
              label=" "
              colon={false}
              labelCol={{
                span: 0,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "100%" }}
                icon={<PlusOutlined />}
                disabled={fields.length >= props.lots.length}
              >
                Add a group
              </Button>
            </Form.Item>
            <Divider />
            <Form.Item type="">
              <Button type="primary" htmlType="submit">
                Submit Homogenization Data
                <RightOutlined />
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
