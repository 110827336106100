import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Empty,
  message,
  Table,
  Typography,
  Form,
  Select,
} from "antd";
import { baseAPIUrl } from "../constants/urlConstants";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { CommentStack } from "../comments/CommentStack";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";
import { EditableDataForm } from "../demands/EditableDataForm";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { WasteLogPDF } from "./WasteLogPDF";

const { Option } = Select;

const { Title, Text } = Typography;
const { Panel } = Collapse;

const noteCols = [
  {
    title: "Matrix",
    dataIndex: "matrix",
    key: "matrix",
  },
  {
    title: "Package ID",
    dataIndex: "package_id",
    key: "package_id",
  },
  {
    title: "Batch ID",
    dataIndex: "batch_id",
    key: "batch_id",
  },
  {
    title: "Quantity",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Med Equivalent",
    dataIndex: "med_equivalent",
    key: "med_equivalent",
  },
  {
    title: "Reason",
    dataIndex: "reason",
    key: "reason",
  },
  {
    title: "Method",
    dataIndex: "method",
    key: "method",
  },
];

export const WasteLogViewer = (props) => {
  const [form] = Form.useForm();
  const [wasteLogs, setWasteLogs] = useState([]);
  const [visible, setVisible] = useState(false);

  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    if (visible) {
      setWasteLogs([]);
      let subUrl = "wastelogs/ticket/";
      subUrl = baseAPIUrl + subUrl + props.id;
      fetch(subUrl, {
        headers: getAuthHeaders(),
      })
        .then((res) => {
          //check if need to login again
          if (res.status === 401) {
            message.info("Please Login Again!");
            logoutUser();
            dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
            return false;
          }
          return res.json();
        })
        .then(
          (results) => {
            if (results) {
              setWasteLogs(results);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, [dispatch, props.id, visible]);

  return (
    <>
      <Button onClick={() => setVisible(true)}>View Waste Logs</Button>
      <Drawer
        title={<Title level={3}>Waste Logs</Title>}
        placement="bottom"
        height="80%"
        open={visible}
        onClose={() => setVisible(false)}
      >
        {!wasteLogs.length && <Empty />}
        {wasteLogs
          .sort((a, b) => a.id - b.id)
          .map((wasteLog, i) => {
            let mainFields = wasteLog.locked.map((field) => (
              <div>
                <b>{field.title}</b>: {field.value}
              </div>
            ));

            let notes = wasteLog.notes;
            return (
              <div>
                <Title level={4}>Waste Log {wasteLog.id}</Title>
                <Text strong type={wasteLog.isComplete ? "success" : "danger"}>
                  Status: {wasteLog.status}
                </Text>

                {mainFields}
                <EditableDataForm
                  fields={wasteLog.editable}
                  type="wasteLog"
                  id={wasteLog.id}
                />
                <Table dataSource={notes} columns={noteCols} />
                <CommentStack wasteID={wasteLog.id} />
                <Divider />
              </div>
            );
          })}
        {!!wasteLogs.length && <PDFDownloadLink
          document={<WasteLogPDF pdfDetails={wasteLogs} ticketName={state.ticket.title} />}
          fileName={state.ticket.title + "-wasteLogs.pdf"}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading PDF document..." : "Download waste logs as a pdf"
          }
        </PDFDownloadLink>}
      </Drawer>
    </>
  );
};
