import React, { useContext } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { Context } from "../context/store";

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    textAlign: "justify",
  },
  commentView: {
    margin: 10,
    padding: 5,
    textAlign: "justify",
  },
  actionView: {
    margin: 5,
    textAlign: "justify",
  },
  text: {
    margin: 2,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  bold: {
    margin: 2,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Bold",
  },
  title: {
    margin: 4,
    fontSize: 35,
    textAlign: "center",
    fontFamily: "Times-Roman",
  },
  subtitle: {
    margin: 4,
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Times-Roman",
  },
  table: {
    fontSize: 14,
    width: 602,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    marginLeft: 5,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 15,
  },
  cell: {
    width: 86,
    fontSize: 12,
    fontFamily: "Times-Roman",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
    border: 1,
    paddingLeft: 1,
  },
});

const LogPage = (props) => {
  const log = props.log;
  return (
    <Page size="letter" wrapping={true}>
      <Text style={styles.title}>
        Waste Log {log.id} for {props.ticketName}
      </Text>
      <Text style={styles.subtitle}>Details</Text>
      <Text style={styles.text} key={"status"}>
        Status: <Text style={styles.bold}>{log.status}</Text>
      </Text>
      {log.locked?.map((detail, i) => {
        return (
          <Text style={styles.text} key={detail.title + i}>
            {detail.title}: <Text style={styles.bold}>{detail.value}</Text>
          </Text>
        );
      })}
      {log.editable?.map((detail, i) => {
        return (
          <Text style={styles.text} key={detail.title + i}>
            {detail.title}: <Text style={styles.bold}>{detail.value}</Text>
          </Text>
        );
      })}

      <Text style={styles.subtitle}>Disposal Notes </Text>
      <NotesTable notes={log.notes} />
      <Text style={styles.subtitle}>Comments </Text>
      {log.comments &&
        log.comments?.map((comment, i) => {
          return (
            <View style={styles.commentView} key={i + comment.timestamp}>
              <Text style={styles.bold}>
                {comment.username} {comment.timestamp}
              </Text>
              <Text style={styles.text}>{comment.commentText}</Text>
            </View>
          );
        })}
    </Page>
  );
};

const NotesTable = (props) => {
  const noteRows = props.notes.map((note, i) => {
    return (
      <View style={styles.row} key={note.id + i}>
        <Text style={styles.cell}>{note.matrix}</Text>
        <Text style={styles.cell}>{note.package_id}</Text>
        <Text style={styles.cell}>{note.batch_id}</Text>
        <Text style={styles.cell}>{note.qty}</Text>
        <Text style={styles.cell}>{note.med_equivalent}</Text>
        <Text style={styles.cell}>{note.reason}</Text>
        <Text style={styles.cell}>{note.method}</Text>
      </View>
    );
  });

  return (
    <View style={styles.table}>
      <View style={styles.row} key={"tableCol"}>
        <Text style={styles.cell}>Matrix</Text>
        <Text style={styles.cell}>Package ID</Text>
        <Text style={styles.cell}>Batch ID</Text>
        <Text style={styles.cell}>Quantity</Text>
        <Text style={styles.cell}>Med Equiv.</Text>
        <Text style={styles.cell}>Reason</Text>
        <Text style={styles.cell}>Method</Text>
      </View>
      {noteRows}
    </View>
  );
};

// Create Document Component
export const WasteLogPDF = (props) => {
  const wasteLogs = props.pdfDetails;
  if (wasteLogs) {
    return (
      <Document
        title={props.ticketName + "-wasteLogs.pdf"}
        author="Halo Infusions"
        subject={"Waste Logs for " + props.ticketName}
        creator="Halo Infusions Cannaban"
        producer="Halo Infusions Cannaban"
        language="English"
      >
        {wasteLogs.map((log) => (
          <LogPage log={log} ticketName={props.ticketName} />
        ))}
      </Document>
    );
  } else {
    return (
      <Document
        title={"Waste Log Error"}
        author="Halo Infusions"
        creator="Halo Infusions Cannaban"
        producer="Halo Infusions Cannaban"
        language="English"
      >
        <Text>
          Unable to generate a PDF. Please report this error to the developers.
        </Text>
      </Document>
    );
  }
};
