import React, { useState, useContext } from "react";
import { Form, Button, Input, message } from "antd";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { baseAPIUrl } from "../constants/urlConstants";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";

const { TextArea } = Input;
export const CommentEditor = ({ onSubmit, stage, wasteID }) => {
  const [value, setValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [state, dispatch] = useContext(Context);

  const handleSubmit = () => {
    if (!value) {
      //must have value to submit
      return;
    }
    setSubmitting(true);

    fetch(baseAPIUrl + "comments", {
      method: "POST",
      body: JSON.stringify({
        text: value,
        id: wasteID ? wasteID : state.ticket.id,
        stage,
      }),
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          setSubmitting(false);
          setValue("");
          if (results.success) {
            message.success(results.message);
          } else {
            message.info(results.message)
          }
        },
        (error) => {
          setSubmitting(false);
          message.error("Note Failed to Post");
          console.log(error);
        }
      );
    if (onSubmit) {
      //if onsubmit is defined call it after submitting
      onSubmit();
    }
  };

  return (
    <>
      <Form.Item>
        <TextArea
          rows={4}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={() => handleSubmit()}
          type="primary"
        >
          Add Note
        </Button>
      </Form.Item>
    </>
  );
};
