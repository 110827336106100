import ACTIONS from "./actions";

const Reducer = (state, action) => {
  const payload = action?.payload;
  switch (action.type) {
    case ACTIONS.SET_USER_DETAILS:
      return {
        ...state,
        key: payload.key,
        username: payload.username,
        role: payload.role,
        ...payload,
        loginError: false,
      };
    case ACTIONS.CLEAR_USER_DETAILS:
      return {
        ...state,
        key: null,
        username: null,
        role: null,
      };
    case ACTIONS.SET_CURRENT_PAGE:
      //console.log(payload);
      return {
        ...state,
        page: payload.page,
      };
    case ACTIONS.SET_STAGES:
      return {
        ...state,
        stages: payload.stages,
      };
    case ACTIONS.SET_EMPLOYEES:
      return {
        ...state,
        employees: payload.employees,
      };
    case ACTIONS.SET_MANAGERS:
      return {
        ...state,
        managers: payload.managers,
      };
    case ACTIONS.SET_TICKET:
      return { ...state, ticket: payload.ticket };
    case ACTIONS.CLEAR_TICKET:
      return {
        ...state,
        ticket: {
          title: null,
          locked: [],
          editable: [],
          nextStage: "",
          ticketLoadTime: null,
        },
      };
    case ACTIONS.RELOAD_TICKET:
      return {
        ...state,
        reloadTicket: !state.reloadTicket,
      };
    case ACTIONS.SUBMIT_EDITABLE_DATA:
      return {
        ...state,
        submitData: !state.submitData,
      };
    default:
      return state;
  }
};

export default Reducer;
