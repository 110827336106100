import React, { useEffect, useState, useContext } from "react";
import { Form, Input, Button, message, Select, Divider, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";
import { logoutUser } from "../helpers/authHelper";
import { baseAPIUrl } from "../constants/urlConstants";

const { Option } = Select;
const { TextArea } = Input;

export const SourceMedAdder = (props) => {
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);
  const [sourceMeds, setSourceMeds] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  //get products and source meds in order to suggest them
  useEffect(() => {
    //get the info on sourcemeds
    fetch(baseAPIUrl + "sourcemeds?status=complete", {
      headers: { user_key: state.key },
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          setSourceMeds(results);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const onAddSourceMeds = (values) => {
    setSubmitting(true);
    console.log("adding source meds");
    console.log(values)
    fetch(baseAPIUrl + "demands/linkmed", {
      method: "POST",
      body: JSON.stringify({
        ticketID: props.id,
        medsToAdd: values.medsToAdd,
        comment: values.noSMExplanation
      }),
      headers: {
        "Content-Type": "application/json",
        user_key: state.key,
      },
    })
      .then((res) => {
        return res.text();
      })
      .then(
        (results) => {
          setSubmitting(false);
          if (results !== "Error adding source meds to ticket") {
            form.resetFields();
            props.onSubmitMed(); //reload card details after success
            message.success(results);
          } else {
            message.error(results);
          }
        },
        (error) => {
          setSubmitting(false);
          message.error("Failed to add source meds");
          console.log(error);
        }
      );
  };

  return (
    <Form
      labelCol={{
        span: 10,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      name="source_med_adder"
      classname="source_med_adder"
      initialValues={{ remember: true }}
      scrollToFirstError
      form={form}
      onFinish={onAddSourceMeds}
    >
      <Divider>Source Med Adder:</Divider>
      <Form.List name="medsToAdd">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <Row>
                <Col span={18}>
                  <Form.Item
                    label="Source Med Batch"
                    className="medID"
                    name={[name, "medID"]}
                    {...restField}
                    fieldKey={[fieldKey, "medID"]}
                    rules={[
                      {
                        message: "Please choose a Source Med!",
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Source Med"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {sourceMeds
                        .filter((med) => med.stage === "complete")
                        .map((med) => (
                          <Option
                            value={med.id}
                          >{`${med.batch}(${med.name})`}</Option>
                        ))}
                    </Select>
                  </Form.Item>{" "}
                </Col>
                <Col span={5}>
                  <Form.Item
                    className="quantity"
                    {...restField}
                    name={[name, "qty"]}
                    fieldKey={[fieldKey, "qty"]}
                    rules={[
                      {
                        message: "Please add the quantity!",
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="QTY" style={{ width: "70px" }} />
                  </Form.Item>
                </Col>
                {
                  <Col span={1}>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(name);
                      }}
                    />
                  </Col>
                }
              </Row>
            ))}
            <Form.Item
              label=" "
              colon={false}
              labelCol={{
                span: 0,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                style={{ width: "100%" }}
                icon={<PlusOutlined />}
              >
                Add a Source Medicine
              </Button>
            </Form.Item>
            <Divider />
          </>
        )}
      </Form.List>
      <Form.Item
        key="noSMExplanation"
        name={"noSMExplanation"}
        hidden={!state.ticket?.disposition?.toLowerCase().includes("remediate")}
        label="Source Med Explanation"
        
      >
        <TextArea
          rows={2}
          size=""
          placeholder="If not adding source meds on remediation explain why"
        />
      </Form.Item>
      <Form.Item label=" " colon={false}>
        <Button
          type="primary"
          htmlType="submit"
          className="submit-ticket-button"
          loading={submitting}
        >
          Confirm Source Meds
        </Button>
      </Form.Item>
    </Form>
  );
};
