import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { loginUser, logoutUser, getAuthHeaders } from "../helpers/authHelper";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";
import { Typography, Spin } from "antd";
import { baseAPIUrl } from "../constants/urlConstants";

const { Text } = Typography;

export const LoginPage = (props) => {
  const [state, dispatch] = useContext(Context);
  const [loginError, setLoginError] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  useEffect(() => {
    //set page
    dispatch({
      type: ACTIONS.SET_CURRENT_PAGE,
      payload: { page: "login" },
    });
  }, []);

  const onFinish = async (values) => {
    setLoggingIn(true);
    if (!changePassword) {
      const userData = await loginUser(
        values.username,
        values.password,
        values.remember
      );
      setLoggingIn(false);
      if (userData) {
        //win95 startup for Murray and comic sans
        if (userData.username === "MurrayS") {
          const audioObj = new Audio(
            "https://www.winhistory.de/more/winstart/mp3/winxp.mp3"
          );
          audioObj.addEventListener("canplaythrough", (event) => {
            /* the audio is now playable; play it if permissions allow */
            audioObj.play();
          });

          let bodyElement = document.getElementById("root");
          bodyElement.style.fontFamily = `font-family: "Comic Sans MS", "Comic Sans", cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;`;
        }

        setLoginError(false);
        dispatch({ type: ACTIONS.SET_USER_DETAILS, payload: userData });
      } else {
        setLoginError(true);
      }
    } else {
      if (values.newPassword === values.newPasswordConfirm) {
        fetch(baseAPIUrl + "users/newpassword", {
          method: "POST",
          body: JSON.stringify({
            username: values.username,
            password: values.password,
            newPassword: values.newPassword,
          }),
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeaders(),
          },
        })
          .then((res) => res.json())
          .then(
            (results) => {
              const resultMessage = results.message;
              setLoggingIn(false);
              console.log(resultMessage);
              if (resultMessage.includes("Incorrect")) {
                message.error(resultMessage);
              } else {
                message.success(resultMessage);
                setChangePassword(false);
              }
            },
            (error) => {
              setLoggingIn(false);
              message.error("Failed to change password");
              console.log(error);
            }
          );
      } else {
        message.error("New passwords do not match");
        setLoggingIn(false);
      }
      //todo check  passwords match and call password reset
    }
  };

  return (
    <div className="site-login-content">
      <Spin tip="Logging in..." delay={300} spinning={loggingIn}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <h2>{changePassword ? "Change Password" : "Login"}</h2>
          {loginError && (
            <Text type="danger">Your username or password was incorrect.</Text>
          )}
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: changePassword ? true : false,
                message: "Please input your New Password!",
              },
            ]}
            hidden={!changePassword}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item
            name="newPasswordConfirm"
            rules={[
              {
                required: changePassword ? true : false,
                message: "Please input your New Password so that they match!",
              },
            ]}
            hidden={!changePassword}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirm new password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              noStyle
              hidden={changePassword}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {changePassword ? "change password" : "Log In"}
            </Button>
          </Form.Item>
        </Form>

        <Button
          type="text"
          onClick={() => {
            setChangePassword(!changePassword);
            setLoginError(false);
          }}
        >
          {!changePassword ? "change password" : "Log In"}
        </Button>
      </Spin>
    </div>
  );
};
