import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Button,
  message,
  DatePicker,
  Typography,
  Divider,
} from "antd";
import { Context } from "../context/store";
import { baseAPIUrl } from "../constants/urlConstants";
import { NoteCreator } from "./NoteCreator";

const { Title, Paragraph, Text } = Typography;

export const WasteLogCreator = (props) => {
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const [submitting, setSubmitting] = useState(false);

  //handles sending data from form to DB
  const onSubmitWasteLog = (values) => {
    console.log("submitting waste log");
    let body = {
      ...values,
      ticket_id: props.id,
    };
    console.log(body);
    setSubmitting(true);
    fetch(baseAPIUrl + "wastelogs", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        user_key: state.key,
      },
    })
      .then((res) => {
        return res.text();
      })
      .then(
        (results) => {
          setSubmitting(false);
          if (results !== "Missing some required paramters. Please try again") {
            form.resetFields();
            message.success(results);
          } else {
            message.error(results);
          }
        },
        (error) => {
          setSubmitting(false);
          message.error("Failed to create the Waste Log");
          console.log(error);
        }
      );
  };

  return (
    <div className="creator-content">
      <Form
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        name="waste_log_creator"
        classname="waste_log_creator"
        initialValues={{ remember: true }}
        scrollToFirstError
        form={form}
        onFinish={onSubmitWasteLog}
      >
        <Title level={3}>Create A Waste Log</Title>
        <Divider />
        <Form.Item label="Created By" name="created_by">
          {state.username}
        </Form.Item>
        {/* <Form.Item
          label="Wasted by DA Name"
          name="da_wasted_by"
          rules={[
            {
              message: "Requires a DA name",
              required: true,
            },
          ]}
        >
          <Input maxLength={100} placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="DA number"
          name="da_number"
          rules={[
            {
              message: "DA number is required",
              required: true,
            },
          ]}
        >
          <Input maxLength={100} placeholder="DA number" />
        </Form.Item>
        <Form.Item
          label="DA expiration date"
          name="da_expiration_date"
          rules={[
            {
              message: "DA must have an expiration date",
              required: true,
            },
          ]}
        >
          <DatePicker
            format="MM/DD/YYYY"
            //disable selecting a day before today
            // disabledDate={(current) =>
            //   current && current < moment().endOf("day").subtract(1, "day")
            // }
          />
        </Form.Item> */}
        <NoteCreator />
        <Title level={4}>
          If turning medication over to authorities, have the receiving
          authority provide the information below
        </Title>
        <Form.Item
          label="Name of Law Enforcement Agency"
          name="law_enforcement_agency"
        >
          <Input maxLength={100} placeholder="LEA Name" />
        </Form.Item>
        <Form.Item
          label="Name and Badge # of receiving authority"
          name="badge_number"
        >
          <Input maxLength={100} placeholder="Badge number" />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button
            type="primary"
            htmlType="submit"
            className="submit-ticket-button"
            loading={submitting}
          >
            Submit Waste Log
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Typography>
        <Paragraph type="secondary">
          Per A.R.S. R9-17-316 Inventory Control System <br />
          C.7. For disposal of medical marijuana or a marijuana product that is
          not to be dispensed or used for making a marijuana product: <br />
          &emsp;a. Description of and reason for the medical marijuana or
          marijuana product being disposed of including, if applicable: <br />
          &emsp;&emsp;i. The number of failed or other unusable plants, and{" "}
          <br />
          &emsp;&emsp;ii. The results of laboratory testing;
          <br />
          &emsp;b. Date of disposal; <br />
          &emsp;c. Method of disposal; and
          <br />
          &emsp;d. Name and registry identification number or license number, as
          applicable, of the dispensary agent or marijuana facility agent
          responsible for the disposal.
        </Paragraph>

        <Divider />
        <Text type="secondary">
          TC = Destroyed by means of mixture with bleach, cat litter and/or
          another abrasive substance
        </Text>
      </Typography>
    </div>
  );
};
