import React, { useState, useContext } from "react";
import { Layout, Menu } from "antd";
import { Context } from "../context/store";
import { logoutUser } from "../helpers/authHelper";
import {
  HomeOutlined,
  FormOutlined,
  // AreaChartOutlined,
  SwitcherOutlined,
  MedicineBoxOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import ACTIONS from "../context/actions";
import { Link } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu } = Menu;

export const MainSideMneu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [state, dispatch] = useContext(Context);

  const onLogout = async () => {
    await logoutUser();
    dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
  };

  const onClickMenuItem = (menuItem) => {
    if (menuItem.key !== "stageSubMenu") {
      dispatch({
        type: ACTIONS.SET_CURRENT_PAGE,
        payload: { page: menuItem.key },
      });
    }
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        zIndex: 2,
      }}
    >
      <Menu
        theme="dark"
        selectedKeys={[state.page]}
        mode="inline"
        onSelect={onClickMenuItem}
      >
        <Menu.Item key="ediblesOverview" icon={<HomeOutlined />}>
          <Link to="/overview" /*/static*/>Edibles Overview</Link>
        </Menu.Item>
        {/* <Menu.Item key="tincturesOverview" icon={<DesktopOutlined />}>
          <Link to="/overview/remediable">Tinctures and Ointments Overview</Link>
        </Menu.Item> */}
        <SubMenu
          key="stageSubMenu"
          icon={<SwitcherOutlined />}
          title="Stage Views"
        >
          {Object.values(state.stages).map((stage) => {
            //removed icon={stage.icon} for now
            return (
              <Menu.Item key={stage.name}>
                <Link to={`/stage/${stage.name}`}>{stage.header}</Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
        <Menu.Item key="createTicket" icon={<FormOutlined />}>
          <Link to="/create">Create Ticket</Link>
        </Menu.Item>
        <Menu.Item key="createSourceMed" icon={<MedicineBoxOutlined />}>
          <Link to="/createmed">Create Source Medicine</Link>
        </Menu.Item>
        <Menu.Item key="reporting" icon={<ArrowDownOutlined />}>
          <Link to="/reporting">Reporting</Link>
        </Menu.Item>
        {/* <Menu.Item key="analysis" icon={<AreaChartOutlined />}>
          <Link to="/analysis">Analysis</Link>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};
