import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import {
  Button,
  Divider,
  InputNumber,
  Input,
  Switch,
  DatePicker,
  Typography,
  Form,
  message,
  Select,
  Spin,
} from "antd";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { Context } from "../context/store";
import { baseAPIUrl } from "../constants/urlConstants";
import ACTIONS from "../context/actions";
import { WasteLogDrawer } from "../wastelogs/WasteLogDrawer";

const { Title } = Typography;
const { Option } = Select;

export const EditableDataForm = (props) => {
  const [dynamicFormItems, setdynamicFormItems] = useState(["start"]);
  const [belowMaxValue, setBelowMaxValue] = useState(false);
  const [wasteExists, setWasteExists] = useState(false);
  const [overlayNeeded, setOverlayNeeded] = useState(false);
  let [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);

  // //use effect to listen for submit data event
  // useEffect(() => {
  //   form.submit();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.submitData]);

  const resetForm = () => {
    setdynamicFormItems(["start"]);
    setBelowMaxValue(false);
    setWasteExists(false);
    setOverlayNeeded(false);
    form.resetFields();
  };

  const onWasteChange = (value, max) => {
    let testingQty = form.getFieldValue("Testing Sample Qty" + props.id);
    if (isNaN(testingQty)) {
      testingQty = 0;
    }
    setBelowMaxValue(value < max - testingQty);
  };

  const onLossChange = () => {
    //TODO: Get these values in a way that allows different names of the fields.
    let testingQty = form.getFieldValue("Testing Sample Qty" + props.id);
    let waste = form.getFieldValue("Waste Loss" + props.id);
    let ncLoss = form.getFieldValue(
      "Non-conforming Loss (Not Wasted)" + props.id
    );

    if (isNaN(testingQty)) {
      testingQty = 0;
    }
    if (isNaN(waste)) {
      waste = 0;
    }
    if (isNaN(ncLoss)) {
      ncLoss = 0;
    }

    //find the initial values of these fields
    const wasteInit =
      props.fields.find((field) => field.colName === "waste_loss")?.value || -1;
    const ncLossInit =
      props.fields.find((field) => field.colName === "nc_loss")?.value || -1;
    const testingQTYInit =
      props.fields.find((field) => field.colName === "testing_qty")?.value ||
      -1;

    console.log(
      `NCInit: ${ncLossInit}, WasteInit: ${wasteInit}, TestingInit: ${testingQTYInit}`
    );
    console.log(`NC: ${ncLoss}, Waste: ${waste}, Testing: ${testingQty}`);

    //Only show if one of the 3 exist AND it is different than what's been saved previously
    setWasteExists(
      (waste && waste !== wasteInit) ||
        (ncLoss && ncLoss !== ncLossInit) ||
        (testingQty && testingQty !== testingQTYInit)
    );
  };

  const onActualPotencyChange = (value, targetPotency) => {
    //console.log(`actual changed to ${value} so overlay? ${value < targetPotency * 0.8 || value > targetPotency * 1.2}`)
    setOverlayNeeded(
      value < targetPotency * 0.8 || value > targetPotency * 1.2
    );
  };

  const onFinish = (values) => {
    //console.log("Success:", values);
    let fieldsToUpdate = dynamicFormItems.map((field) => {
      let fieldValue = values[field.name + props.id];
      return { fieldName: field.colName, fieldValue: fieldValue };
    });
    console.log(fieldsToUpdate);

    let subUrl = "";
    if (props.type === "demand") {
      subUrl = "demands/";
    } else if (props.type === "wasteLog") {
      subUrl = "wastelogs/update/";
    } else {
      subUrl = "sourcemeds/";
    }
    fetch(baseAPIUrl + subUrl + props.id, {
      method: "POST",
      body: JSON.stringify({
        fieldsToUpdate,
        comment:
          belowMaxValue || wasteExists
            ? "Yield reduction explained as: \n"+form.getFieldValue("wasteExplanation" + props.id)
            : null,
      }),
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          console.log("saved....");
          if (props.type !== "wasteLog") {
            resetForm();
            dispatch({ type: ACTIONS.RELOAD_TICKET });
            message.success("Saved data");
          } else {
            message.success(results.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Error saving data");
    console.log("Failed:", errorInfo);
  };

  //build form from editable data
  useEffect(() => {
    resetForm();
    //clear the form if it is still holding onto anything
    const formItemsTemp = props.fields?.map((field, index) => {
      const type = field.type;
      const name = field.title;
      const colName = field.colName;
      const wasteCheck = field.wasteCheck;
      const explanationRequired = field.explanationRequired;
      const defaultValue = field.default;
      const disabled = !!field.disabled;
      let value = field.value === null ? 0 : field.value;
      let max = field.max; //not truly max. Just max expected but can be excedded
      let rules = [
        { type: type === "dropdown" || type === "text" ? "string" : type },
      ];
      let fieldInput;

      //if it is NC loss, testing qty, or waste loss must save initial value for reference when checking for waste
      // if (colName === "waste_loss") {
      //   console.log(`setting wasteinit to: ${value}`)
      //   setWasteInit(value);
      // } else if (colName === "nc_loss") {
      //   console.log(`setting ncLossinit to: ${value}`)
      //   setNcLossInit(value);
      // } else if (colName === "testing_qty") {
      //   console.log(`setting testingQTYinit to: ${value}`)
      //   setTestingQTYInit(value);
      // }

      if (wasteCheck) {
        let testingQty = form.getFieldValue("Testing Sample Qty" + props.id);
        if (isNaN(testingQty)) {
          testingQty = 0;
        }
        setBelowMaxValue(value < max - testingQty);
      }

      switch (type) {
        case "number":
          rules = [{ ...rules[0], min: 0 }];
          if (wasteCheck) {
            fieldInput = (
              <InputNumber onChange={(e) => onWasteChange(e, max)} />
            );
          } else if (explanationRequired) {
            fieldInput = <InputNumber onChange={(e) => onLossChange(e, max)} />;
          } else if (colName === "actual_potency") {
            //if there is no value make init  default target potency for product
            if (!value) {
              value = defaultValue;
            } else {
              //else if there is a value check if overlay needs hiding or not.
              onActualPotencyChange(value, defaultValue);
            }
            fieldInput = (
              <InputNumber
                onChange={(e) => onActualPotencyChange(e, defaultValue)}
              />
            );
          } else if (colName === "actual_potency_cbd") {
            //if there is no value make init  default target potency for product
            if (!value) {
              value = defaultValue;
            } else {
              //else if there is a value check if overlay needs hiding or not.
              onActualPotencyChange(value, defaultValue);
            }
            fieldInput = (
              <InputNumber
                onChange={(e) => onActualPotencyChange(e, defaultValue)}
              />
            );
          } else {
            fieldInput = <InputNumber />;
          }

          rules = [{ ...rules[0] }];

          break;
        case "boolean":
          fieldInput = <Switch defaultChecked={value} />;
          break;
        case "date":
          console.log("parsing date: ", value);
          value = moment(value);
          console.log(`to: ${value}`);
          console.log("testing nan");
          console.log(typeof value);
          fieldInput = (
            <DatePicker
              showTime={field.hasTime ? { format: "HH:mm" } : null}
              format="MM/DD/YYYY"
              disabled={disabled}
            />
          );
          break;
        case "dropdown":
          fieldInput = (
            <Select placeholder="select a storage location">
              {field.values.map((value) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          );
          break;
        case "employees":
        case "managers":
          fieldInput = (
            <Select
              showSearch
              placeholder="Employee"
              allowClear={false}
              disabled={disabled}
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
            >
              {state[type].map((employee) => (
                <Option value={employee.id}>{`${employee.name}`}</Option>
              ))}
            </Select>
          );
          break;
        case "text":
          fieldInput = <Input />;
          break;
        default:
          return (
            <p>
              There was an error parsing input field {name} of type invalid{" "}
              {type}
            </p>
          );
      }

      let initVal =
        isNaN(value) || typeof value === "object" ? value : Number(value);

      if ((type === "employee" || type === "date") && field.value === null) {
        initVal = null; //want value to remain null if it is in this case
      }

      return {
        name,
        rules,
        colName,
        jsxObject: (
          <Form.Item
            label={name}
            key={name + props.id}
            name={name + props.id}
            rules={rules}
            initialValue={initVal} //if its a number make it one else it can stay as a string
            //hidden={colName === "label_potency" && !overlayNeeded} //hide if it is the  overlay sticker and it is determined that it is not needed due to potency
          >
            {fieldInput}
          </Form.Item>
        ),
      };
    });
    //console.log("setting form items due to change in props.field");
    //console.log(formItemsTemp);
    setdynamicFormItems(formItemsTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fields, props.id]);

  useEffect(() => {
    if (props.submitNow) {
    }
  }, [props.submitNow]);

  return (
    !!props.fields.length && (
      <Spin spinning={dynamicFormItems[0] === "start"}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={props.onChangeValues}
        >
          <Divider>
            <Title level={5}>Please Fill In</Title>
          </Divider>
          {dynamicFormItems?.map((formElement) => {
            if (formElement.colName === "label_potency" && !overlayNeeded) {
              //if overlay not needed don't return that field
              return null;
            }
            return formElement?.jsxObject;
          })}
          <Form.Item
            key="wasteExplanation"
            name={"wasteExplanation" + props.id}
            hidden={!(belowMaxValue || wasteExists)}
            label="Note on yield reduction"
            rules={[
              {
                validator(_, value) {
                  if (!(belowMaxValue || wasteExists) || value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`Must Explain Yield Reduction`)
                  );
                },
              },
            ]}
          >
            <Input placeholder="Explain the waste or samples" />
          </Form.Item>{" "}
          <Form.Item type="">
            <Button type="primary" htmlType="submit">
              Save Data
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    )
  );
};
