import React, { useState } from "react";
import { Drawer, Button, Tooltip } from "antd";
import { WasteLogCreator } from "./WasteLogCreator";

export const WasteLogDrawer = (props) => {
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Tooltip title="Create Waste Log for this ticket">
        <Button onClick={() => setVisible(true)} type="primary">
          Create a Waste Log
        </Button>
      </Tooltip>

      <Drawer
        open={visible}
        onClose={onClose}
        placement="bottom"
        height="80%"
      >
        <WasteLogCreator id={props.id}/>
      </Drawer>
    </>
  );
};
