import React from "react";
import { Divider, Row, Spin, Typography } from "antd";

const { Title } = Typography;

export const CannabanPage = (props) => {
  return (
    <Spin size="large" spinning={props.loading}>
      <div className="site-layout-content" style={{boxShadow: "5px 5px 10px grey",}}>
        {props.header && <Row
          style={{
            position: "sticky",
            top: "64px",
            left: "90px",
            zIndex: 1,
            background: "white",
          }}
        >
          <Title level={1}>
            {props.icon} {props.header}
          </Title>
          <Divider />
        </Row>}
        {props.children}
      </div>
    </Spin>
  );
};
