import React, { useContext, useState } from "react";
import {
  Button,
  Divider,
  Modal,
  Skeleton,
  message,
  Typography,
  Tooltip,
  Popconfirm,
  Collapse,
  notification,
  Row,
  Col,
} from "antd";
import { LeftOutlined, RightOutlined, DeleteOutlined } from "@ant-design/icons";
import { CommentStack } from "../comments/CommentStack";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";
import { EditableDataForm } from "./EditableDataForm";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import { DemandPDF } from "./DemandPDF";
import { baseAPIUrl } from "../constants/urlConstants";
import { DemandTimeline } from "./DemandTimeline";
import { HomogenizationCreator } from "../sourcemedicines/HomogenizationCreator";
import { WasteLogViewer } from "../wastelogs/WasteLogViewer";
import { SourceMedAdder } from "../sourcemedicines/SourceMedAdder";
import { SignoffList } from "../signoffs/SignoffList";
import { WasteLogDrawer } from "../wastelogs/WasteLogDrawer";
import { isManager } from "../helpers/employeeHelper";

const { Panel } = Collapse;

const { Title } = Typography;

export const CardDetail = (props) => {
  //const [ticketDetails, setTicketDetails] = useState(emptyTicketObject);

  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const handleBackStage = () => {
    let subUrl = state.ticket.type === "demand" ? "demands/" : "sourcemeds/";
    fetch(baseAPIUrl + subUrl + "prevstage/" + state.ticket.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          console.log("Results of update: ", results);
          if (results.success) {
            message.success(`Succesfully moved to the previous stage`);
            handleFinish();
          } else {
            message.info(
              `Could not move production record back: \n${results.message}`
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleUpdateStage = () => {
    //on update first save data
    //dispatch({ type: ACTIONS.SUBMIT_EDITABLE_DATA });
    //then update stage
    let subUrl = state.ticket.type === "demand" ? "demands/" : "sourcemeds/";
    setLoading(true);
    fetch(
      baseAPIUrl +
        subUrl +
        "nextstage/" +
        state.ticket.id +
        "?stage=" +
        state.ticket.stage,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeaders(),
        },
      }
    )
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          console.log("Results of update: ", results);
          if (results.success) {
            //if the ticket has WIP med and moved from awaiting conversion give an alert about removing from inventory
            console.log(
              "is awaiting conv: ",
              state.ticket.stage === state.stages.AWAITINGCONVERSIONS?.name
            );

            //see if any source med is wip
            let hasWIPMed = state.ticket.sourceMedDetails?.find((sm) => {
              return sm.batch_id.toLowerCase().includes("wip");
            });

            if (
              state.ticket.stage === state.stages.AWAITINGCONVERSIONS?.name &&
              !!hasWIPMed
            ) {
              notification.info({
                message: `Remove ${hasWIPMed.batch_id} from Inventory!`,
                description: `Because ${state.ticket.batch_id} is includes ${hasWIPMed.batch_id} please ensure you have removed it from inventory since conversion is now complete.`,
                duration: 0,
              });
            }

            message.success(`Success! ${results.message}`);
            handleFinish();
          } else {
            message.info(
              `Could not move production record${
                results.message ? ": " + results.message : "."
              }`
            );
          }
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
  };

  const parseLockedFields = (lockedFields) => {
    return lockedFields?.map((field) => {
      const name = field.title;
      let value = field.value;
      let valueNum = parseFloat(value);
      //trim excess decimals
      if (!isNaN(valueNum)) {
        //value = valueNum.toPrecision(2);
      }

      if (name === "Product Source Med Offset") {
        //if offset as wel
        value = value * 100;
        value = value + "%";
      }

      if (value?.length > 375) {
        //if very long text collapse it
        value = (
          <Collapse>
            <Panel key="1" header={value.substr(0, 50) + "..."}>
              {value}
            </Panel>
          </Collapse>
        );
      }
      return (
        <p key={name}>
          {name}: {field.isCalculated ? <b>{value}</b> : value}
        </p>
      );
    });
  };

  const zebraLabelInfo = (labelInfo) => {
    return (
      <Row>
        <Col span="12">
          <Row key="batchid">
            <b>Batch ID: {labelInfo?.batchID}</b>
          </Row>
          <Row key="sourcebatch">
            <b>Source Batch: {labelInfo?.sourceBatch}</b>
          </Row>
          <Row key="saledate">
            <b>Sale Date: {labelInfo?.dateReceived}</b>
          </Row>
          <Row key="harvestdate">
            <b>Harvest Date: {labelInfo?.harvestDate}</b>
          </Row>
          <Row key="strain">
            <b>Strain: {labelInfo?.strain}</b>
          </Row>
          <Row key="netweight">
            <b>Total Net Wt: {labelInfo?.netWeight}</b>
          </Row>
          <Row key="usablemjweight">
            <b>Usable MJ Wt: {labelInfo?.usableMJWeight}</b>
          </Row>
          <Row key="bestbydate">
            <b>Best Used By: {labelInfo?.bestByDate}</b>
          </Row>
          <Row key="sourcemel">
            <b>Source MEL: {labelInfo?.sourceMEL}</b>
          </Row>
          <Row key="mfgmel">
            <b>Mfg MEL: {labelInfo?.mfgMEL}</b>
          </Row>
        </Col>
        <Col span="12">
          <Row key="mfgdate">
            <b>Mfg Date: {labelInfo?.mfgDate}</b>
          </Row>
          <Row key="extractionmethod">
            <b>Extraction Method: {labelInfo?.extractionMethod}</b>
          </Row>
          <Row key="form">
            <b>Form: {labelInfo?.form}</b>
          </Row>
          {/*           <Row key="storagezone">
            <b>Storage Zone: {labelInfo?.storageZone}</b>
          </Row> */}
          <Row key="sampledate">
            <b>Sample Date: {labelInfo?.sampleDate}</b>
          </Row>
          <Row key="totalthc">
            <b>Total THC: {labelInfo?.actualPotencyTHC}</b>
          </Row>
          <Row key="totalcbd">
            <b>Total CBD: {labelInfo?.actualPotencyCBD}</b>
          </Row>
          <Row key="madewith">
            <b>MADE WITH {labelInfo?.madeWith}</b>
          </Row>
        </Col>
      </Row>
    );
  };

  const deleteTicket = () => {
    let subUrl = state.ticket.type === "demand" ? "demands/" : "sourcemeds/";
    fetch(
      baseAPIUrl + subUrl + state.ticket.id + "?stage=" + state.ticket.stage,
      {
        method: "DELETE",
        headers: {
          ...getAuthHeaders(),
        },
      }
    )
      .then((res) => {
        //check if need to login again
        if (res.status === 1) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          message.info(results.message);
          if (results.success) {
            handleFinish();
          }
        },
        (error) => {
          message.error("Error deleting ticket");
          console.log(error);
        }
      );
  };

  const getFooterButtons = () => {
    const stage = state.ticket.stage;
    let footerButtons = [
      <DemandTimeline
        id={state.ticket.id}
        stage={state.ticket.stage}
        name={state.ticket.batchID}
        type={state.ticket.ticketType}
        key="demandTimeline"
      />,
      stage === state.stages.MATERIALREADYSAMPLE?.name ? null : (
        <Tooltip title="Move ticket back a stage" key="backstage">
          <Button disabled={loading} onClick={handleBackStage}>
            <LeftOutlined />
          </Button>
        </Tooltip>
      ),
      //remove check for prod if removing prepack in in kitchen prod
      stage === state.stages.PREPROCESS?.name ? null : (
        <Tooltip title={state.ticket.nextStage} key="updateStage">
          <Button
            type="primary"
            onClick={handleUpdateStage}
            //if in testinghold and dispo is retest disable since it simple sits in this stage
            disabled={
              (stage === state.stages.TESTINGHOLD?.name &&
                state.ticket.disposition === "Retest") ||
              loading
            }
          >
            <RightOutlined />
          </Button>
        </Tooltip>
      ),
    ];

    //if allowed show delete card button
    if (isManager(state.role)) {
      footerButtons.splice(
        1,
        0,
        <Popconfirm
          title="Are you sure you want to delete the ticket?"
          okText="Yes"
          onConfirm={deleteTicket}
          key="delete"
        >
          <Button disabled={loading} type="ghost" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      );
    }
    return <>{footerButtons}</>;
  };

  const handleFinish = () => {
    //enable this line to turn autosave on ticket close on
    //dispatch({ type: ACTIONS.SUBMIT_EDITABLE_DATA });
    dispatch({ type: ACTIONS.CLEAR_TICKET });
  };

  return (
    <Modal
      open={state.ticket.title}
      title={
        <Title level={4}>
          {state.ticket.type === "demand"
            ? state.ticket.batchID
            : state.ticket.title}
        </Title>
      }
      onCancel={handleFinish}
      footer={getFooterButtons()}
    >
      <div style={{ minHeight: "300px" }}>
        {
          <Skeleton
            active
            loading={!state.ticket.title}
            paragraph={{ rows: 5 }}
          />
        }
        {!!state.ticket?.locked.length && (
          <Divider>
            <Title level={5}>Ticket Information</Title>
          </Divider>
        )}
        {parseLockedFields(state.ticket?.locked)}
        {!!state.ticket?.locked.length && (
          <PDFDownloadLink
            document={
              <DemandPDF
                pdfDetails={state.ticket}
                employees={state.employees}
              />
            }
            fileName={state.ticket.title + "-" + state.ticket.stage + ".pdf"}
          >
            {({ blob, url, loading, error }) =>
              loading
                ? "Loading PDF document..."
                : "Download production record as PDF"
            }
          </PDFDownloadLink>
        )}
        {!!state.ticket?.locked.length && (
          <p>
            <BlobProvider
              document={
                <DemandPDF
                  pdfDetails={state.ticket}
                  employees={state.employees}
                />
              }
            >
              {({ url }) => (
                <a href={url} target="_blank" rel="noreferrer">
                  Print production record
                </a>
              )}
            </BlobProvider>
          </p>
        )}
        {(state.ticket.stage === "labeling" ||
          state.ticket.stage === "awaiting_conversions" ||
          state.ticket.stage === "archive") &&
          state.ticket.ticketType !== "WIP" &&
          !!state.ticket?.zebraInfo && (
            <Divider>
              <Title level={5}>Compliance Info</Title>
            </Divider>
          )}
        {(state.ticket.stage === "labeling" ||
          state.ticket.stage === "awaiting_conversions" ||
          state.ticket.stage === "archive") &&
          state.ticket.ticketType !== "WIP" &&
          !!state.ticket?.zebraInfo &&
          zebraLabelInfo(state.ticket.zebraInfo)}
        {
          <EditableDataForm
            type={state.ticket.type}
            fields={state.ticket.editable}
            id={state.ticket.id}
          />
        }
        <SignoffList />
        {state.ticket.stage === state.stages.PRODUCTION?.name ? (
          <SourceMedAdder id={state.ticket.id} /> //onSubmitMed={loadCardDetails} />
        ) : null}
        {state.ticket.type === "demand" && (
          <>
            <Divider>Waste Logs</Divider>
            Waste logs for this ticket are available here. You can...
            <br />
            <WasteLogDrawer id={state.ticket.id} />
            <br />
            <br />
            <WasteLogViewer id={state.ticket.id} />
            <Divider />
          </>
        )}
        {state.ticket.stage === state.stages.PREPROCESS?.name ? (
          <HomogenizationCreator
            id={state.ticket.id}
            batchID={
              state.ticket.locked.find((field) => field.title === "Batch ID")
                ?.value
            }
            packages={state.ticket.packaging}
            lots={state.ticket?.lots}
            onSubmitGroups={handleFinish}
          />
        ) : null}
        <CommentStack stage={state.ticket.stage} />
      </div>
    </Modal>
  );
};
