import React from "react";
import { Form, Input, Button, Divider, Space, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export const LotCreator = (props) => {
  return (
    <>
      <Divider>Lot info:</Divider>
      <Form.List name="lots" initialValue={[1]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <>
                <Space>
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    fieldKey={[fieldKey, "name"]}
                  >
                    <Input placeholder="lot name" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "gross_weight"]}
                    fieldKey={[fieldKey, "gross_weight"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing gross weight",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Gross wt (g)" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "net_weight"]}
                    fieldKey={[fieldKey, "net_weight"]}
                    rules={[{ required: true, message: "Missing Net Weight" }]}
                  >
                    <InputNumber placeholder="Net Wt (g)" />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Space>
              </>
            ))}
            <Form.Item
              label=" "
              colon={false}
              labelCol={{
                span: 0,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "80%" }}
                icon={<PlusOutlined />}
              >
                Add a lot
              </Button>
            </Form.Item>
            <Divider />
          </>
        )}
      </Form.List>
    </>
  );
};
