import React from "react";
import {  Row, Tooltip, Typography } from "antd";
const { Title } = Typography;

export const ColorLegend = (props) => {
  const colors = [
    { name: "Release", RGB: "rgb(117,251,76)" },
    //{ name: "Preliminary", RGB: "rgb(241, 158, 56)" },
    //{ name: "Hold", RGB: "rgb(140, 26,245)" },
    { name: "Retest", RGB: "rgb(234, 51, 247)" },
    { name: "Relabel & Release", RGB: "rgb(234,51,25)" },
    //{ name: "Relabel & Preliminary", RGB: "rgb(254,255,84)" },
    { name: "Destroy", RGB: "rgb(254,255,84)" },
    //{ name: "Retest", RGB: "rgb(89,133,225)" },
    { name: "Remediate", RGB: "rgb(223,157,155)" },
  ];

  return (
    <Tooltip
      title={
        <div>
          {colors.map((color,i) => {
            return (
              <Row key={i}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: color.RGB,
                  }}
                />
                : {color.name}
              </Row>
            );
          })}
        </div>
      }
    >
      <Title level={4} style={{ marginTop: "13px" }}>
        Hover for Legend
      </Title>
    </Tooltip>
  );
};
