import "./ContentPage.css";
import React, { useContext, useEffect } from "react";
import { Layout, notification, message } from "antd";
import { StagesTable } from "./StagesTable";
import { LoginPage } from "./LoginPage";
import { Context } from "../context/store";
import CannabanHeader from "./CannabanHeader";
import { DemandCreator } from "../demands/DemandCreator";
import { MainSideMneu } from "./MainSideMenu";
import { StageView } from "./StageView";
//import { AnalysisView } from "./AnalysisView";
import { getStages } from "../helpers/stageHelper";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ACTIONS from "../context/actions";
import { SourceMedCreator } from "../sourcemedicines/SourceMedCreator";
import { getEmployees, getManagers } from "../helpers/employeeHelper";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { baseAPIUrl } from "../constants/urlConstants";
import { CardDetail } from "../demands/CardDetail";
import { ReportView } from "./ReportView";
import { Redirect } from "react-router";
const { Content, Footer } = Layout;

export const ContentPage = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    setInterval(
      () =>
        notification.warning({
          message: "Page refresh incoming",
          description:
            "The cannaban needs to be refreshed periodically to ensure that it is up to date. The page will refresh in 30 seconds.",
          duration: 30,
          placement: "top",
          onClose: () => window.location.reload(),
        }),
      1000 * 60 * 60 * 2
    );
  }, []);

  useEffect(() => {
    if (state.ticket.title) {
      loadTicketDetails(
        state.ticket.id,
        state.ticket.stage,
        state.ticket.type === "med"
      );
    }
  }, [state.reloadTicket]);

  const loadTicketDetails = (ticketID, stage, isMed) => {
    //dispatch({ type: ACTIONS.CLEAR_TICKET });
    let subUrl = isMed ? "sourcemeds/" : "demands/";
    subUrl = baseAPIUrl + subUrl + ticketID + "?stage=" + stage;
    fetch(subUrl, {
      headers: getAuthHeaders(),
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          console.log("results to set ticket details");
          console.log(results);
          if (results) {
            dispatch({
              type: ACTIONS.SET_TICKET,
              payload: {
                ticket: {
                  ...results,
                  id: ticketID,
                  type: isMed ? "med" : "demand",
                  ticketLoadTime: Date.now(),
                },
              },
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    //if not logged in redirect to login page
    //else load stages
    if (!state.key) {
      navigate("/login");
    } else {
      if (!Object.values(state.stages).length) {
        //if no stages get them.
        getStages().then((stages) => {
          dispatch({ type: ACTIONS.SET_STAGES, payload: { stages } });
        });
      }
      if (!Object.values(state.employees).length) {
        //if no employees get them.
        getEmployees().then((employees) => {
          dispatch({ type: ACTIONS.SET_EMPLOYEES, payload: { employees } });
        });
      }
      if (!Object.values(state.managers).length) {
        //if no managers get them.
        getManagers().then((managers) => {
          dispatch({ type: ACTIONS.SET_MANAGERS, payload: { managers } });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.key, state.page]);

  //"3918px"
  return (
    <Layout
      className="layout"
      style={{
        width:
          state.page.indexOf("Overview") >= 0
            ? (Object.values(state.stages).length - 2) * 410 + 350
            : "auto",
      }}
    >
      <CardDetail />
      {state.key && <MainSideMneu />}
      <Layout style={{ marginLeft: state.key ? 80 : 0 }}>
        <CannabanHeader />
        <Content className="site-background">
          <Routes>
            <Route path="/create" element={<DemandCreator />} />
            <Route path="/createmed" element={<SourceMedCreator />} />
            <Route path="/reporting" element={<ReportView />} />
            {/* <Route path="/analysis">
                <AnalysisView />
              </Route> */}
            {/* remove the seperate static path and remake remiadble to overview
            <Route path="/overview/static">
              <StagesTable type="static" />
            </Route> */}
            <Route
              path="/overview"
              element={<StagesTable loadTicketDetails={loadTicketDetails} />}
            />
            <Route
              path={`/stage/${state.page}`}
              element={
                <StageView
                  stage={state.page}
                  loadTicketDetails={loadTicketDetails}
                />
              }
            />
            <Route
              path="/login"
              element={state.key ? <Navigate to="/" /> : <LoginPage />}
            />
            <Route
              path="/"
              element={<StagesTable loadTicketDetails={loadTicketDetails} />}
            />
          </Routes>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Cannaban 2022 Created by Phillip Gotobed (v2.69)
        </Footer>
      </Layout>
    </Layout>
  );
};
