import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";
import Cookies from "js-cookie";

const initialState = {
  key: Cookies.get("userKey"),
  username: Cookies.get("username"),
  role: Cookies.get("userRole"),
  page: "ediblesOverview" /*"createTicket"*/,
  stages: {},
  employees: [],
  managers: [],
  ticket: {
    title: null,
    locked: [],
    editable: [],
    nextStage: "",
    ticketLoadTime: null,
  },
  reloadTicket: false,
  submitData: false,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
