import React from "react";
import { Card, Typography } from "antd";

const { Title } = Typography;

export const MedCard = (props) => {

  return (
    <>
      {/* <CardDetail
        type="medicine"
        id={props.med.id}
        stage={props.med.stage}
        visible={showmed}
        onCancel={handleCloseModal}
        title={props.med.batch ? props.med.batch : props.med.med_name}
        reload={props.reload}
      /> */}
      <Card
        hoverable
        style={{
          margin: "7px",
          borderColor: "black",
          borderWidth: "2px",
          padding: "0px 0px 0px 0px",
        }}
        onClick={props.loadCardDetails}
      >
        <div
          style={{
            backgroundColor: props.med.color.background,
            borderWidth: "0 0 2px 0",
            borderStyle: "solid",
          }}
        >
          <Title
            level={3}
            ellipsis={{ tooltip: props.med.batch }}
            style={{ color: props.med.color.text, paddingTop: "10px" }}
          >
            {props.med.batch ? props.med.batch + "" : props.med.med_name + ""}
          </Title>
        </div>
        <p>name: {props.med.med_name}</p>
        {/* <p>Number of Batches: {props.med.number_of_batches}</p> */}
        <p>vendor: {props.med.vendor  }</p>
      </Card>
    </>
  );
};
