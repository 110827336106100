import React, { useContext } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Card, Typography, Tooltip, Badge, Alert } from "antd";

const { Title } = Typography;

export const DemandCard = (props) => {
  let losses = props.ticket.waste_loss + props.ticket.nc_loss;
  if (props.ticket.stage === "ready_sample") losses += props.ticket.testing_qty;

  const cardObj = (
    <Card
      hoverable
      style={{
        margin: "7px",
        borderColor: "black",
        borderWidth: "2px",
        padding: "0px 0px 0px 0px",
      }}
      onClick={props.loadCardDetails}
    >
      <div
        style={{
          backgroundColor: props.ticket.color.background,
          borderWidth: "0 0 2px 0",
          borderStyle: "solid",
        }}
      >
        <Title
          level={3}
          ellipsis={{ tooltip: props.ticket.final_product_batch_id }}
          style={{ color: props.ticket.color.text, paddingTop: "10px" }}
        >
          {props.ticket.isLate ? (
            <Tooltip title="Ticket is running late!">
              <ClockCircleOutlined />
            </Tooltip>
          ) : null}
          {" " + props.ticket.final_product_batch_id}
        </Title>
      </div>
      {props.ticket.prepack_completed &&
        (props.ticket.stage === "production" ||
          props.ticket.stage === "material_labeling") && (
          <p style={{ color: "red" }}>Prepackaging has been completed</p>
        )}
      {props.ticket.kitchen_completed &&
        (props.ticket.stage === "production" ||
          props.ticket.stage === "material_labeling") && (
          <p style={{ color: "red" }}>Kitchen production has been completed</p>
        )}
      {/*<p>Product: {props.ticket.product_name}</p>*/}
      {!!props.ticket.cc_order_status && (
        <p>Testing status: {props.ticket.cc_order_status}</p>
      )}
      {/* <p>Number of Batches: {props.ticket.number_of_batches}</p> */}
      {(props.ticket.stage === "production" ||
        props.ticket.stage === "material_labeling") && (
        <p>Theoretical Yield (packages): {props.ticket.theoretical_qty}</p>
      )}
      {(props.ticket.stage === "production" ||
        props.ticket.stage === "material_labeling") && (
        <p>Units produced (eaches): {props.ticket.processing_yield}</p>
      )}
      {(props.ticket.stage === "production" ||
        props.ticket.stage === "material_labeling") && (
        <p>Materials Prepared: {props.ticket.materials_qty}</p>
      )}
      {props.ticket.stage === "packaging" && (
        <p>Processing Yield (packages): {props.ticket.good_yield}</p>
      )}
      {props.ticket.stage === "packaging" && (
        <p>Packaging Yield: {props.ticket.good_yield - losses}</p>
      )}
      {props.ticket.stage === "ready_sample" && (
        <p>Packaging Yield: {props.ticket.good_yield}</p>
      )}
      {props.ticket.stage === "ready_sample" && (
        <p>On-hold Yield: {props.ticket.good_yield - losses}</p>
      )}
      {(props.ticket.stage === "awaiting_test_results" ||
        props.ticket.stage === "testing_hold" ||
        props.ticket.stage === "labeling") && (
        <p>On-hold Yield: {props.ticket.good_yield}</p>
      )}
      {(props.ticket.stage === "awaiting_test_results" ||
        props.ticket.stage === "testing_hold") && (
        <p>On-hold Yield at end of stage: {props.ticket.good_yield - losses}</p>
      )}
      {props.ticket.stage === "labeling" && (
        <p>Labeled Yield: {props.ticket.good_yield - losses}</p>
      )}
      {props.ticket.stage === "awaiting_conversions" && (
        // TODO: Remove labeled yield and partial lot for WIP tickets
        <p>Labeled Yield: {props.ticket.good_yield}</p>
      )}
      {props.ticket.stage === "awaiting_conversions" && (
        <p>
          Good Yield:{" "}
          {props.ticket.good_yield -
            losses -
            (props.ticket.box_quantity
              ? (props.ticket.good_yield - losses) % props.ticket.box_quantity
              : 0)}
        </p>
      )}
      {props.ticket.stage === "awaiting_conversions" && (
        <p>
          Partial Lot:{" "}
          {props.ticket.box_quantity
            ? (props.ticket.good_yield - losses) % props.ticket.box_quantity
            : 0}
        </p>
      )}
      {!!props.ticket.storage_location && (
        <p>Location: {props.ticket.storage_location}</p>
      )}
      {(!!props.ticket.daysInCol && props.ticket.daysInCol < 7 && (
        <p>Days in Stage: {props.ticket.daysInCol}</p>
      )) ||
        (!!props.ticket.daysInCol && (
          <p style={{ color: "red" }}>
            Days in Stage: {props.ticket.daysInCol}
          </p>
        )) || <p>Days in Stage: Less than 1</p>}
    </Card>
  );

  if (props.ticket.numIncompleteWasteLogs === 1) {
    return (
      <Badge.Ribbon text={`1 waste log`}>
        {cardObj}
      </Badge.Ribbon>
    );
  } else if (props.ticket.numIncompleteWasteLogs > 1) {
    return (
      <Badge.Ribbon
        text={`${props.ticket.numIncompleteWasteLogs} waste logs`}
      >
        {cardObj}
      </Badge.Ribbon>
    );
  }

  return cardObj;
};
