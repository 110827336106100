import { baseAPIUrl } from "../constants/urlConstants";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { message } from "antd";

const MANAGER_ROLES = ["superadmin", "admin", "manager"];
const ADMIN_ROLES = ["superadmin", "admin"];

export async function getEmployees() {
  let employees = await fetch(baseAPIUrl + "employees", {
    headers: getAuthHeaders(),
  })
    .then((res) => {
      //check if need to login again
      if (res.status === 401) {
        message.info("Please Login Again!");
        logoutUser();
        //then finish this function and put it in main page so it fetches stages if they don't exist
      }
      return res.json();
    })
    .catch((error) => {
      console.log(`employee fetch failed: \n${error}`);
      return null;
    });
  console.log("employees gotten");
  return employees;
}

export async function getManagers() {
  let managers = await fetch(baseAPIUrl + "managers", {
    headers: getAuthHeaders(),
  })
    .then((res) => {
      //check if need to login again
      if (res.status === 401) {
        message.info("Please Login Again!");
        logoutUser();
        //then finish this function and put it in main page so it fetches stages if they don't exist
      }
      return res.json();
    })
    .catch((error) => {
      console.log(`manager fetch failed: \n${error}`);
      return null;
    });
  console.log("managers gotten");
  return managers;
}

export function isManager(role){
  return MANAGER_ROLES.includes(role)
}