import React, { useEffect, useState, useContext } from "react";
import { Row, message, Empty, Button, Tooltip, Typography, Input } from "antd";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { DemandCard } from "../demands/DemandCard";
import { isStage } from "../helpers/stageHelper";
import ACTIONS from "../context/actions";
import { Context } from "../context/store";
import { CannabanPage } from "./CannabanPage";
import { baseAPIUrl } from "../constants/urlConstants";
import { ColorLegend } from "./ColorLegend";


const { Title } = Typography;

export const StageView = (props) => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useContext(Context);
  const [stageDetails, setStageDetails] = useState(
    isStage(props.stage, state.stages)
  );
  const [searchTerm, setSearchTerm] = useState("");

  function loadData() {
    //set page
    dispatch({
      type: ACTIONS.SET_CURRENT_PAGE,
      payload: { page: props.stage },
    });
    setLoading(true);
    setStageDetails(isStage(props.stage, state.stages));
    //reload data if ticket has been hidden

    fetch(baseAPIUrl + "demands?stage=" + props.stage, {
      headers: getAuthHeaders(),
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          let stageTickets = results[props.stage];
          setLoading(false);
          if (stageTickets) {
            setTickets(stageTickets);
          } else {
            setTickets([]);
          }
        },
        (error) => {
          message.error("Failed to load tickets in " + props.stage);
          setLoading(false);
          console.log(error);
        }
      );
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stage]);

  return (
    <>
      <div
        style={{
          position: "sticky",
          left: "85px",
        }}
      >
        <Row style={{ marginBottom: "20px", width: "100vw" }}>
          <Tooltip title="Search tickets in this stage">
            <Title level={2} style={{ marginTop: "13px" }}>
              Search Tickets:
            </Title>
          </Tooltip>
          <Input
            style={{ width: "350px", fontSize: "20px", marginRight: "20px" }}
            placeholder="Press enter to search"
            onPressEnter={(e) => setSearchTerm(e.target.value)}
          />
          <ColorLegend />
        </Row>
      </div>
      <CannabanPage
        header={stageDetails.header}
        icon={stageDetails.icon}
        loading={loading}
      >
        {tickets && tickets.length ? (
          <Row>
            {tickets
              ?.filter((ticket) =>
                JSON.stringify(ticket)
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map((ticket, i) => {
                return (
                  <DemandCard
                    key={i}
                    ticket={ticket}
                    reload={loadData}
                    loadCardDetails={() => {
                      props.loadTicketDetails(
                        ticket.ticket_id,
                        props.stage,
                        false
                      );
                    }}
                  />
                );
              })}
          </Row>
        ) : (
          <Empty description="No Tickets in this stage!">
            <Button
              type="primary"
              onClick={() =>
                dispatch({
                  type: ACTIONS.SET_CURRENT_PAGE,
                  payload: { page: "createTicket" },
                })
              }
            >
              Create Ticket Now
            </Button>
          </Empty>
        )}
      </CannabanPage>
    </>
  );
};
