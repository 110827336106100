import React, { useEffect, useState, useContext } from "react";
import { Comment, List, message, Skeleton } from "antd";
import { CommentEditor } from "./CommentEditor";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import ACTIONS from "../context/actions";
import { Context } from "../context/store";
import { baseAPIUrl } from "../constants/urlConstants";

export const CommentStack = ({ username, editor = true, stage, wasteID }) => {
  const [comments, setComments] = useState(["loading"]);
  const [submitted, setSubmitted] = useState(false);
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    setComments(["loading"]);
    let urlParams = "";
    if (wasteID) {
      //if there is a wasteid prop this is a comment stack for a waste log
      urlParams += "id=" + wasteID;
      urlParams += "&stage=wastelog";
    } else if (state.ticket.id) {
      //else use id for ticket as normal
      urlParams += "id=" + state.ticket.id;
    }

    if (username) {
      urlParams += "&username=" + username;
    }
    if (stage) {
      urlParams += "&stage=" + stage;
    }

    fetch(baseAPIUrl + "comments?" + urlParams, {
      headers: getAuthHeaders(),
    })
      .then((res) => {
        //check if need to login
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          setComments(results);
        },
        (error) => {
          console.log(error);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ticket.id, username, submitted, stage]);

  return (
    <>
      <List
        className="comment-list"
        header={
          <>
            <h3>Notes</h3>
            {comments[0] === "loading"
              ? null
              : comments.length + ` note${comments.length !== 1 ? "s" : ""}`}
          </>
        }
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={(item) => {
          if (item !== "loading") {
            return (
              <li>
                <Comment
                  author={item.username}
                  content={
                    <p>
                      <b>{item.stage}</b>
                      <p>{item.commentText}</p>
                    </p>
                  }
                  datetime={item.timestamp}
                />
              </li>
            );
          } else {
            return (
              <li>
                <Skeleton active />
              </li>
            );
          }
        }}
      />
      {editor && comments[0] !== "loading" && (
        <CommentEditor
          stage={wasteID ? "wasteLog" : stage}
          wasteID={wasteID}
          onSubmit={() => setSubmitted(!submitted)}
        />
      )}
    </>
  );
};
// comments !== "loading" &&
