import "./App.css";
import React from "react";
import Store from "./context/store";
import { ContentPage } from "./overview/ContentPage";

function App() {
  return (
    <Store>
      <div
        className="App"
      >
        <ContentPage />
      </div>
    </Store>
  );
}

export default App;
