import React, { useEffect, useState, useContext } from "react";
import {
  Divider,
  Row,
  Col,
  Typography,
  message,
  Input,
  Tooltip,
  Button,
} from "antd";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { FolderOpenOutlined, RightOutlined } from "@ant-design/icons";
import { DemandCard } from "../demands/DemandCard";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";
import { CannabanPage } from "./CannabanPage";
import { baseAPIUrl } from "../constants/urlConstants";
import { MedCard } from "../sourcemedicines/MedCard";
import { Link } from "react-router-dom";
import { ColorLegend } from "./ColorLegend";

const { Title } = Typography;

export const StagesTable = (props) => {
  //A Search needs to search "tickets" object
  const [tickets, setTickets] = useState([]);
  const [sourceMeds, setSourceMeds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [state, dispatch] = useContext(Context);

  function loadData() {
    setLoading(true);
    //reload data if ticket has been hidden
    fetch(baseAPIUrl + `demands${props.type ? "?type=" + props.type : ""}`, {
      headers: getAuthHeaders(),
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          setLoading(false);
          setTickets(results);
        },
        (error) => {
          setLoading(false);
          message.error("Failed to load production records");
          console.log(error);
        }
      );

    //also load source med data
    fetch(baseAPIUrl + `sourcemeds?status=incomplete`, {
      headers: getAuthHeaders(),
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          setLoading(false);
          setSourceMeds(results);
        },
        (error) => {
          setLoading(false);
          message.error("Failed to load source meds");
          console.log(error);
        }
      );
  }

  useEffect(() => {
    const isStatic = props.type === "static";

    //if there is not a ticket then load the main page. This ensures the page reloads when a ticket is cleared
    if (!state.ticket.title) {
      loadData();
      //set page
      dispatch({
        type: ACTIONS.SET_CURRENT_PAGE,
        payload: { page: isStatic ? "ediblesOverview" : "tincturesOverview" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, state.ticket.id]);
  //boxShadow: "0 5px 10px grey"

  const StageCol = (props) => {
    // console.log(
    //   `building ${props.stage.header} col from \n${props.stage} for  ${props.stage.name}`
    // );
    //console.log(tickets);
    //console.log(tickets[props.stage.name]);

    return (
      <Col style={{ width: "400px", margin: "5px" }}>
        <div
          style={{
            position: "sticky",
            top: "64px",
            background: "white",
            zIndex: 1,
            //shadow for col headers. If need only buttom may need to exteract headers to own row and box xhadow that.
            boxShadow: "0px 8px 10px grey",
          }}
        >
          <Title level={2}>{props.stage.header}</Title>
          <Divider />
        </div>
        {tickets[props.stage.name]
          ?.filter((ticket) =>
            JSON.stringify(ticket)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
          .sort((t1, t2) => {
            const tsT1 = new Date(t1.created_timestamp);
            const tsT2 = new Date(t2.created_timestamp);
            return tsT1 - tsT2;
          })
          .map((ticket, i) => {
            return (
              <DemandCard
                ticket={ticket}
                reload={loadData}
                loadCardDetails={() => {
                  props.loadTicketDetails(
                    ticket.ticket_id,
                    props.stage.name,
                    false
                  );
                }}
                key={i}
              />
            );
          })}
        {sourceMeds[props.stage.name]
          ?.filter((ticket) =>
            JSON.stringify(ticket)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
          .map((sourceMed, i) => {
            return (
              <MedCard
                med={sourceMed}
                key={i}
                loadCardDetails={() =>
                  props.loadTicketDetails(sourceMed.id, props.stage.name, true)
                }
              />
            );
          })}
      </Col>
    );
  };

  return (
    <>
      
      <div
        style={{
          position: "sticky",
          left: "85px",
          width: 650,
        }}
      >
        <Row style={{ marginBottom: "20px", width: "100vw" }}>
          <Tooltip title="Search tickets not archived">
            <Title level={2} style={{ marginTop: "13px" }}>
              Search Tickets:
            </Title>
          </Tooltip>
          <Input
            style={{ width: "350px", fontSize: "20px", marginRight: "20px" }}
            placeholder="Press enter to search"
            onPressEnter={(e) => setSearchTerm(e.target.value)}
          />
          <ColorLegend />
        </Row>
      </div>
      <CannabanPage loading={loading}>
        <Row>
          {Object.values(state.stages).map((stage, index) => {
            return stage.name !== "archive" && stage.name !== "complete" ? (
              <StageCol
                stage={stage}
                type={props.type}
                key={stage.name}
                loadTicketDetails={props.loadTicketDetails}
              />
            ) : null;
          })}
          <Tooltip title="To Archive">
            <Link to={`/stage/archive`}>
              <Button
                size="large"
                style={{
                  fontSize: "30px",
                  height: "65px",
                  position: "sticky",
                  top: "64px",
                  background: "white",
                  zIndex: 1,
                  boxShadow: "0px 8px 10px grey",
                  margin: "5px",
                }}
                onClick={() =>
                  dispatch({
                    type: ACTIONS.SET_CURRENT_PAGE,
                    payload: { page: "archive" },
                  })
                }
              >
                <FolderOpenOutlined />
                <RightOutlined />
              </Button>
            </Link>
          </Tooltip>
        </Row>
      </CannabanPage>
    </>
  );
};
