const ACTIONS = {
    SET_USER_DETAILS: "SET_USER_DETAILS",
    CLEAR_USER_DETAILS: "CLEAR_USER_DETAILS",
    SET_CURRENT_PAGE: "SET_CURRENT_PAGE", 
    SET_STAGES: "SET_STAGES",
    SET_EMPLOYEES: "SET_EMPLOYEES",
    SET_MANAGERS: "SET_MANAGERS",
    SET_TICKET: "SET_TICKET",
    CLEAR_TICKET: "CLEAR_TICKET",
    RELOAD_TICKET: "RELOAD_TICKET",
    SUBMIT_EDITABLE_DATA: "SUBMIT_EDITABLE_DATA",
}

export default ACTIONS;