import React,  { useEffect, useState, useContext }  from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { Context } from "../context/store";

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    textAlign: "justify",
  },
  commentView: {
    margin: 10,
    padding: 5,
    textAlign: "justify",
  },
  actionView: {
    margin: 5,
    textAlign: "justify",
  },
  text: {
    margin: 2,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  bold: {
    margin: 2,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Bold",
  },
  title: {
    margin: 4,
    fontSize: 50,
    textAlign: "center",
    fontFamily: "Times-Roman",
  },
  subtitle: {
    margin: 4,
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Times-Roman",
  },
  table: {
    fontSize: 14,
    width: 550,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  row: {
    margin: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 15,
  },
  cell: {
    margin: 2,
    fontSize: 14,
    fontFamily: "Times-Roman",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
  },
});

//Uses details object from getTicketDetails from the backend.
const DetailsPage = (props) => {

const employees = props.employees
console.log("DESPITE POPULAR BELEIF EMPOYEES MAY EXIDST ")
console.log(employees)
  const pageDetails = props.pageDetails;
  console.log("Page details for DetailsPage/Demand PDF....");
  console.log(pageDetails);
  
  return (
    <Page size="letter" wrapping={true}>
      <View style={styles.section}>
        <Text style={styles.title}>{pageDetails.title}</Text>
        <Text style={styles.subtitle}>
          {pageDetails.stageHeader} {pageDetails.stageInstance}
        </Text>
        {pageDetails.locked?.map((detail, i) => {
          return (
            <Text style={styles.text} key={detail.title + i}>
              {detail.title}: <Text style={styles.bold}>{detail.value}</Text>
            </Text>
          );
        })}
        {pageDetails.stage === "labeling" && pageDetails.zebraInfo && (
          <Text style={styles.subtitle}>Zebra Label Info</Text>
        )}
        {pageDetails.stage === "labeling" && pageDetails.zebraInfo && (
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.cell}>
                Batch ID: {pageDetails.zebraInfo?.batchID}
              </Text>
              <Text style={styles.cell}>
                Mfg Date: {pageDetails.zebraInfo?.mfgDate}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell}>
                Source Batch: {pageDetails.zebraInfo?.sourceBatch}
              </Text>
              <Text style={styles.cell}>
                Expiration: {pageDetails.zebraInfo?.bestByDate}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell}>
                Sale Date: {pageDetails.zebraInfo?.dateReceived}
              </Text>
              <Text style={styles.cell}>
                Extraction Method: {pageDetails.zebraInfo?.extractionMethod}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell}>
                Strain: {pageDetails.zebraInfo?.strain}
              </Text>
              {/* <Text style={styles.cell}>Storage Zone: {pageDetails.zebraInfo?.storageZone}</Text> */}
              <Text style={styles.cell}>
                Form: {pageDetails.zebraInfo?.form}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell}>
                Total Net Wt: {pageDetails.zebraInfo?.netWeight}
              </Text>
              <Text style={styles.cell}>
                Sample Date: {pageDetails.zebraInfo?.sampleDate}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell}>
                Usable MJ Wt: {pageDetails.zebraInfo?.usableMJWeight}
              </Text>
              <Text style={styles.cell}>
                Total THC: {pageDetails.zebraInfo?.actualPotencyTHC}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell}>
                Source MEL: {pageDetails.zebraInfo?.sourceMEL}
              </Text>
              <Text style={styles.cell}>
                Total CBD: {pageDetails.zebraInfo?.actualPotencyCBD}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell}>
                Mfg MEL: {pageDetails.zebraInfo?.mfgMEL}
              </Text>
              <Text style={styles.cell}>
                MADE WITH {pageDetails.zebraInfo?.madeWith}
              </Text>
            </View>
          </View>
        )}
        <Text style={styles.subtitle}>Employee Signoffs</Text>
        {pageDetails.signoffs?.map((signoff, i) => {
          return (
            signoff.employeeIDs && (
              <Text style={styles.text} key={signoff.signoff + i}>
                {signoff.signoff} by:{" "}
                {signoff.employeeIDs?.map((id) => {
                  let employee= employees.find(employee => employee.id === id)
                  
                  return <Text style={styles.bold}>{employee?.name}{", "}</Text>;
                })}
              </Text>
            )
          );
        })}
      </View>
    </Page>
  );
};

const CommentsPage = (props) => {
  const pageDetails = props.pageDetails;
  return (
    <Page size="letter" wrapping={true}>
      <Text style={styles.subtitle}>Notes</Text>
      {pageDetails.comments &&
        pageDetails.comments?.map((comment, i) => {
          return (
            <View style={styles.commentView} key={i + comment.timestamp}>
              <Text style={styles.bold}>
                {comment.username} {comment.timestamp} {comment.stage}
              </Text>
              <Text style={styles.text}>{comment.commentText}</Text>
            </View>
          );
        })}
    </Page>
  );
};

const ActionsPage = (props) => {
  const pageDetails = props.pageDetails;
  return (
    <Page size="letter" wrapping={true}>
      <Text style={styles.subtitle}>Action History</Text>
      {pageDetails.actions &&
        pageDetails.actions?.map((action, i) => {
          return (
            <View style={styles.actionView} key={i + action.timestamp}>
              <Text style={styles.bold}>
                {action.username} {action.timestamp}
              </Text>
              <Text style={styles.text}>{action.actionText}</Text>
            </View>
          );
        })}
    </Page>
  );
};

// Create Document Component
export const DemandPDF = (props) => {

  const pdfDetails = props.pdfDetails;
  const snapshotArray = pdfDetails.snapshotArray;
  if (snapshotArray && pdfDetails) {
    return (
      <Document
        title={pdfDetails.title + "-" + pdfDetails.stageHeader}
        author="Halo Infusions"
        subject={"Ticket information for " + pdfDetails.title}
        creator="Halo Infusions Cannaban"
        producer="Halo Infusions Cannaban"
        language="English"
      >
        <DetailsPage employees={props.employees} pageDetails={pdfDetails} />
        <CommentsPage pageDetails={pdfDetails} />
        {snapshotArray.length > 0 &&
          snapshotArray
            ?.map((snap, i) => {
              return <DetailsPage employees={props.employees} pageDetails={snap} key={i} />;
            })
            .reverse()}
        <ActionsPage pageDetails={pdfDetails} />
      </Document>
    );
  } else if (pdfDetails) {
    return (
      <Document
        title={pdfDetails.title + "-" + pdfDetails.stageHeader}
        author="Halo Infusions"
        subject={"Ticket information for " + pdfDetails.title}
        creator="Halo Infusions Cannaban"
        producer="Halo Infusions Cannaban"
        language="English"
      >
        <DetailsPage employees={props.employees} pageDetails={pdfDetails} />
        <CommentsPage pageDetails={pdfDetails} />
        <Page size="letter">
          <View>
            <Text>No history for this production record.</Text>
          </View>
        </Page>
      </Document>
    );
  } else {
    return (
      <Document
        title={"Production Record Error"}
        author="Halo Infusions"
        creator="Halo Infusions Cannaban"
        producer="Halo Infusions Cannaban"
        language="English"
      >
        <Text>
          Unable to generate a PDF. Please report this error to the developers.
        </Text>
      </Document>
    );
  }
};
