import { baseAPIUrl } from "../constants/urlConstants";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { message } from "antd";

export async function getStages() {
  let stages = await fetch(baseAPIUrl + "stages", {
    headers: getAuthHeaders(),
  })
    .then((res) => {
      //check if need to login again
      if (res.status === 1) {
        message.info("Please Login Again!");
        logoutUser();
        //then finish this function and put it in main page so it fetches stages if they don't exist
      }
      return res.json();
    })
    .catch((error) => {
      console.log(`stage fetch failed: \n${error}`);
      return null;
    });
  console.log("stages gotten");
  return stages;
}

export function isStage(stageName, stages) {
  //for each stage in stages get the stages name and compare
  return Object.values(stages).find((stage) => stage.name === stageName);
}
