import Cookies from "js-cookie";

import { baseAPIUrl } from "../constants/urlConstants";
import { message } from "antd";

//const [state, dispatch] = useContext(Context);

/**
 * @returns object with current userkey to use as headers
 */
export function getAuthHeaders() {
  const userKey = Cookies.get("userKey");
  return { user_key: userKey };
}

/**
 * Logs in a user and returns an object with details or false based on the result
 * @param {String} username
 * @param {String} password
 * @returns {Boolean | Object} result
 */
export async function loginUser(username, password, remember) {
  let resultsOfLogin = await fetch(baseAPIUrl + "users/login", {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      //if a key was gotten
      const userKey = res.key;
      if (userKey) {
        //set cookies to remember who is currently logged in
        message.success(res.message);
        if (!remember) {
          //if we do not want to remember the user default to seesion cookie
          Cookies.set("username", username);
          Cookies.set("userKey", userKey);
          Cookies.set("userRole", res.role);
        } else {
          //otherwise they expire in 30 days
          Cookies.set("username", username, { expires: 30 });
          Cookies.set("userKey", userKey, { expires: 30 });
          Cookies.set("userRole", res.role, { expires: 30 });
        }
        return {
          username,
          key: userKey,
          role: res.role,
        };
      } else {
        message.error(res.message);
        return false;
      }
    })
    .catch((error) => {
      console.log(`Log in failed: \n${error}`);
      return null;
    });
  return resultsOfLogin;
}

/**
 * Logs out the currently logged in user. Returns true or false depending on result
 * @returns {Boolean} result
 */
export async function logoutUser() {
  let result = await fetch(baseAPIUrl + "users/logout", {
    method: "POST",
    body: JSON.stringify({
      username: Cookies.get("username"),
      key: Cookies.get("userKey"),
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        message.error("Failed to logout! Status: " + res.status);
        return false;
      }
    })
    .then((res) => {
      message.success(res.message);
      Cookies.remove("username");
      Cookies.remove("userKey");
      Cookies.remove("userRole");
      return true;
    });
  return result;
}

//TODO refactor to use this function as react hook as needed
// export async function checkUserStatus(status) {
//   //check if need to login again
//   if (status === 401) {
//     message.info("Please Login Again!");
//     logoutUser();
//     dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
//     return false;
//   }
//   return true;
// }
